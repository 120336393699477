import React, { Component } from 'react';

class App extends Component {

    constructor() {
      super();
        
      this.state = {
          salsaAmount: 1000,
          salsaMeasurementType: 'ml'
      };

      this.handleAmountChanged = this.handleAmountChanged.bind(this);
      this.handleMeasurementTypeChanged = this.handleMeasurementTypeChanged.bind(this);
    }

    handleAmountChanged(event) {
        this.setState({salsaAmount: event.target.value});
        this.calculateAndSetWeight(event.target.value, this.state.salsaMeasurementType);
    }

    handleMeasurementTypeChanged(event) {
        this.setState({salsaMeasurementType: event.target.value});
        this.calculateAndSetWeight(this.state.salsaAmount, event.target.value);
    }

    calculateAndSetWeight(amount, measurementType) {
        let amountInMl = parseFloat(amount);

        if (Number.isNaN(amountInMl)) {
            amountInMl = 0;
        } else if ('cups' === measurementType) {
            amountInMl *= 236.588;
        } else if ('oz' === measurementType) {
            amountInMl *= 29.5735;
        }

        let amountInGrams = 'mg' === measurementType ? amountInMl : amountInMl * 1.0947;

        this.props.setSalsaWeightInGrams(amountInGrams);
    }

    render() {
        return (
            <div className="salsa-amount-section">
                <div>Amount of Salsa</div>
                <input type="number" min="0" step="0.5" value={this.state.salsaAmount} onChange={this.handleAmountChanged}/>
                <select value={this.state.salsaMeasurementType} onChange={this.handleMeasurementTypeChanged}>
                    <option value="ml">ml</option>
                    <option value="mg">mg</option>
                    <option value="oz">ounces</option>
                    <option value="cups">cups</option>
                </select>
            </div>
        );
    }
}
 
export default App;