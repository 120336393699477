import React, { Component } from 'react';

import Config from './Config';

import AddChileList from './AddChileList';
import ChileRow from './ChileRow';

class Chiles extends Component {

    constructor() {
        super();
        
        this.state = {
            chiles: []
        };

        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handleNewQuantity = this.handleNewQuantity.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.addChile = this.addChile.bind(this);
    }

    addChile(chileName) {
        let chileDef = Config.chileDefs[chileName];
        let chiles = this.state.chiles;
        chiles.push({name: chileDef.name, quantity: 0, minShuWeight: 0, avgShuWeight: 0, maxShuWeight: 0});
        this.handleNewQuantity(chiles, chiles.length - 1, 1);
    }

    handleQuantityChange(e, index) {
        this.handleNewQuantity(this.state.chiles, index, e.target.value);
    }

    handleNewQuantity(chiles, index, value) {
        let chileDef = Config.chileDefs[chiles[index].name];

        chiles[index].quantity = value;

        if (!Number.isNaN(value)) {
            chiles[index].minShuWeight = chiles[index].quantity * chileDef.weight * chileDef.minShu;
            chiles[index].avgShuWeight = chiles[index].quantity * chileDef.weight * ((chileDef.maxShu + chileDef.minShu)/2);
            chiles[index].maxShuWeight = chiles[index].quantity * chileDef.weight * chileDef.maxShu;
        } else {
            chiles[index].minShuWeight = 0;
            chiles[index].avgShuWeight = 0;
            chiles[index].maxShuWeight = 0;
        }
        
        this.setState({'chiles': chiles});
        this.caclulateShuTotals(chiles);
    }

    handleDelete(index) {
        let chiles = this.state.chiles;

        if (window.confirm(`Are you sure you want to take the ${chiles[index].name} out of the salsa?`)) {
            chiles = chiles.filter((_, arrayIndex) => arrayIndex !== index);
            this.setState({'chiles': chiles});
            this.caclulateShuTotals(chiles);
        }
    }

    caclulateShuTotals(chiles) {
        let min = 0, avg = 0, max = 0;

        if (chiles.length > 0) {
            min = chiles.map(chile => chile.minShuWeight).reduce((a, b) => a + b);
            avg = chiles.map(chile => chile.avgShuWeight).reduce((a, b) => a + b);
            max = chiles.map(chile => chile.maxShuWeight).reduce((a, b) => a + b);
        }

        this.props.setShuWeights(min, avg, max);
    }

    render() {
        return (
            <div className="chiles-section">
                <div>Chiles in salsa:</div>
                {this.buildChileList()}
                <AddChileList addChile={this.addChile} />
            </div>
        );
    }

    buildChileList() {
        if (this.state.chiles.length === 0) {
            return 'Your salsa has no chiles. :(';
        }

        return this.state.chiles.map((chile, index) => 
            <ChileRow
                key={index}
                index={index}
                chile={chile}
                handleDelete={this.handleDelete}
                handleQuantityChange={this.handleQuantityChange} />
        );
    }
}
 
export default Chiles;