import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

import JalapenoResult from './JalapenoResult';

class Result extends Component {

    render() {
        let min = 0, avg = 0, max = 0;

        if (this.props.weightInGrams > 0) {
            min = this.props.shuWeights.min / this.props.weightInGrams;
            avg = this.props.shuWeights.avg / this.props.weightInGrams;
            max = this.props.shuWeights.max / this.props.weightInGrams;
        }

        return (
            <div className="result-container">
                <div>Estimated SHU</div>
                <div className="section">Average:</div>
                <NumberFormat value={avg} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                <div className="section">Min/max</div>
                <NumberFormat value={min} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                &nbsp;-&nbsp;
                <NumberFormat value={max} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                <JalapenoResult averageShu={avg} />
                <small>estimates are based on average weights of pepper pods and the typical min/max scoville units for those pepper pods.</small>
            </div>
        );
    }
}
 
export default Result;