import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

import Config from './Config';

class JalapenoResult extends Component {

    constructor() {
        super();

        const jalapeno = Config.chileDefs['Jalapeno'];
        this.jalapenoAverage = (jalapeno.minShu + jalapeno.maxShu) / 2;
    }

    render() {
        let timesHotter = this.props.averageShu / this.jalapenoAverage;

        return (
            <div className="section">
                <NumberFormat value={timesHotter} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                <span>x hotter than a jalapeno</span>
            </div>
        );
    }
}
 
export default JalapenoResult;