import React, { Component } from 'react';

import Config from './Config';

class AddChileList extends Component {

    constructor() {
        super();

        this.handleAddClicked = this.handleAddClicked.bind(this);
    }

    handleAddClicked() {
        this.props.addChile(this.addChileInput.value);
    }

    render() {
        const items = Object.values(Config.chileDefs)
            .map(chile => <option key={chile.name} value={chile.name}>{chile.name}</option>);

        return (
            <div className="add-chile-list">
                <select ref={(input) => this.addChileInput = input}>{items}</select>
                <button onClick={this.handleAddClicked}>Add to salsa</button>
            </div>
        );
    }
}
 
export default AddChileList;