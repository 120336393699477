import React, { Component } from 'react';

import SalsaAmount from './SalsaAmount';
import Chiles from './Chiles';
import Result from './Result';

import './App.css';

class App extends Component {

    constructor() {
        super();
        
        this.state = {
            salsaWeightInGrams: 1094.7,
            shuWeights: {
                min: 0,
                avg: 0,
                max: 0
            }
        };

        this.setSalsaWeightInGrams = this.setSalsaWeightInGrams.bind(this);
        this.setShuWeights = this.setShuWeights.bind(this);
    }

    setSalsaWeightInGrams(weight) {
        this.setState({'salsaWeightInGrams': weight});
    }

    setShuWeights(min, avg, max) {
        let shuWeights = {
            min: min,
            avg: avg,
            max: max
        }
        this.setState({'shuWeights': shuWeights});
    }

    render() {
        return (
            <div className="app-container">
                <h1><small>(Wildly Inaccurate)</small> Salsa Scoville Estimator</h1>
                <SalsaAmount setSalsaWeightInGrams={this.setSalsaWeightInGrams} />
                <Chiles setShuWeights={this.setShuWeights}/>
                <Result weightInGrams={this.state.salsaWeightInGrams} shuWeights={this.state.shuWeights}/>
            </div>
        );
    }
}
 
export default App;