import React, { Component } from 'react';

class ChileRow extends Component {

    render() {
        let quantity = this.props.chile.quantity;
        let suffix = quantity === 1 || quantity === '1' ? '' : 's';

        return (
            <div className="chile-row">
                <button onClick={() => this.props.handleDelete(this.props.index)}>X</button>
                <input type="number" min="0" step="0.5" value={this.props.chile.quantity} onChange={(e) => this.props.handleQuantityChange(e, this.props.index)}/>
                {this.props.chile.name}{suffix}
            </div>
        );
    }
}
 
export default ChileRow;