class Config {

    static chileDefs = {
        'Bell pepper': {name: 'Bell pepper', weight: 0, minShu: 0, maxShu: 0},
        'Banana pepper': {name: 'Banana pepper', weight: 46, minShu: 0, maxShu: 500},
        'Poblano': {name: 'Poblano', weight: 17, minShu: 1000, maxShu: 1500},
        'Anaheim': {name: 'Anaheim', weight: 45, minShu: 500, maxShu: 2500},
        'Hatch': {name: 'Hatch', weight: 45, minShu: 1000, maxShu: 8000},
        'Jalapeno': {name: 'Jalapeno', weight: 14, minShu: 2500, maxShu: 8000},
        'Serrano': {name: 'Serrano', weight: 6.1, minShu: 10000, maxShu: 23000},
        'Cayenne': {name: 'Cayenne', weight: 5, minShu: 30000, maxShu: 50000},
        'Tobasco': {name: 'Tobasco', weight: 5, minShu: 30000, maxShu: 50000},
        'Pequin': {name: 'Pequin', weight: 2.5, minShu: 30000, maxShu: 60000},
        'Thai chili': {name: 'Thai chili', weight: 2.5, minShu: 50000, maxShu: 100000},
        'Habanero': {name: 'Habanero', weight: 8.7, minShu: 100000, maxShu: 350000},
        'Ghost pepper': {name: 'Ghost pepper', weight: 7.96, minShu: 855000, maxShu: 1041427},
        'Carolina reaper': {name: 'Carolina reaper', weight: 7.96, minShu: 1569300, maxShu: 1569300},
        'Scorpion': {name: 'Scorpion', weight: 7.96, minShu: 1200000, maxShu: 2000000},
    };
}
 
export default Config;